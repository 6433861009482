'use strict';
 var linkCss = document.createElement("link");
 linkCss.rel = "stylesheet";
 linkCss.href = "https://fonts.googleapis.com/css?family=Nunito:300,400,700,900";
 var siteHeadElem = document.head || document.getElementsByTagName("head")[0];
 siteHeadElem.appendChild(linkCss);
var barbaJs = {
	init: function(){
		Barba.Pjax.start();
		Barba.Dispatcher.on('transitionCompleted', function() {
			
			$('html, body').scrollTop(0);
			mm_track_pageview(location.pathname);
			siteGlobal.rebuildAllEvents();

		});

		Barba.Dispatcher.on('newPageReady',function(currentStatus, oldStatus, container){
			var js = container.querySelectorAll("script");
			if(js != null && js.length>0){
				for(i in js){
					if(js[i].innerHTML!=='') eval(js[i].innerHTML);
					if(js[i].src!=='') $.getScript(js[i].src);
				}
			}
		});

		Barba.Dispatcher.on('linkClicked', function() {
      menuOverlay.toggle(false);
		});

		Barba.Pjax.getTransition = function() {
			return FadeTransition;
		};

		Barba.Pjax.originalPreventCheck = Barba.Pjax.preventCheck;
		
		Barba.Pjax.preventCheck = function(evt, element) {
			if (!Barba.Pjax.originalPreventCheck(evt, element)) {
				return false;
				alert('');
			}
			// No need to check for element.href -
			// originalPreventCheck does this for us! (and more!)
			if (/.pdf/.test(element.href.toLowerCase())) {
				return false;
			}
			return true;
		};
	}
}

FadeTransition = Barba.BaseTransition.extend({
	start: function() {
    $('#barba-wrapper').addClass('loading');
		Promise.all([this.newContainerLoading, this.fadeOut()]).then(this.fadeIn.bind(this));
	},
	fadeOut: function() {
		return $(this.oldContainer).animate({ opacity: 0 }).promise();
	},

	fadeIn: function() {
		var _this = this;
		var $el = $(this.newContainer);

		$(this.oldContainer).hide();

		$el.css({
			visibility : 'visible',
			opacity : 0
		});

		$el.animate({ opacity: 1 }, 400, function() {
      $('#barba-wrapper').removeClass('loading');
			_this.done();
		});
	}
});
'use strict';
var menuOverlay = {

  mainClass:'.menu-overlay',
  isOpen : false,

  
  init:function(){
    this.btn();
  },

  btn:function(){
    var $btn = $(this.mainClass+'__toggler');
    $btn.click(function(){
      menuOverlay.toggle();
    });
  },
  
  toggle: function(state){
    var $wrap = $(this.mainClass+'__wrap');
    this.isOpen = (typeof state !== 'undefined') ? state : !this.isOpen;

    $wrap.toggleClass('menu-open', this.isOpen);
  }





};
'use strict';
var visual = {
  mainClass: '.visual',

  init: function(){
    this.slider();
    this.btnScroll();
    this.initWaypoint();
  },

  load: function(){
    var $slider = $(this.mainClass+'__slider');
    $slider.slick('slickPlay');
  },

  btnScroll: function(){
    $btn = $(this.mainClass + '__box-text__btn');
    if($btn.attr('href') == 'javascript:;') {   
      $btn.click(function(){
        siteGlobal.goToPos('.box-hotels');
      });
    }
  },

  slider: function(){

    var $slider = $(this.mainClass+'__slider');
    var $arrows = $(this.mainClass+'__arrow');

    if($slider.length===0) return false;

    $slider.slick({
      slidesToShow: 1,
      slidesToScroll: 1,
      arrows: false,
      dots: false,
      speed: 800,
      fade: true,
      autoplay: false,
      autoplaySpeed: 8000,
      cssEase: 'linear'
    }); 

    // Navigazione frecce
    $arrows.on('click',function(){
      var $el = $(this);
      var verse = $el.data('verse');
      $slider.slick('slick'+verse);
    });

  },

  initWaypoint:function(){

    $visual = $(this.mainClass);
    
    if($visual.length===0) return false;

    
    $visual.waypoint(function(direction){
      if ($visual.length === 0) return false;

      $('body').toggleClass('home-inital-view',(direction=='up'));
    }, {  
      offset:'-50%' 
    });
  
  }

}
'use strict';
var galleryBody = {

  mainClass: '.gallery-body',

  init: function () {
    this.slider();
  },

  slider: function () {
    $sliders = $(this.mainClass + '__slider');
    $sliders.slick({
      slidesToShow: 1,
      slidesToScroll: 1,
      arrows: true,
      dots: false,
      speed: 400,
      autoplay: false,
      cssEase: 'ease-out',
      prevArrow: '<button type="button" class="slick-prev"></button>',
      nextArrow: '<button type="button" class="slick-next"></button>',
    });

    $sliders.on('afterChange',function(){
      // Walkaround per lazyload e waypoints su gallery
      $(window).trigger('resize');
    });
  }



};
'use strict';
var barTop = {

  mainClass:'.bar-top',
  
  init:function(){
  },

  checkVisual:function(){
    var $visual = $('.visual');
    $('body').toggleClass('home-inital-view',$visual.length>0);
    setTimeout(function(){
      siteGlobal.goToPosOffsetCalc();
    },300);
  },



};
'use strict';
var listHotels = {

  mainClass:'.list-hotels',
  
  init:function(){
    this.sliders();
  },

  sliders:function(){
    $sliders = $(this.mainClass+'__slider');
    $sliders.slick({
      slidesToShow: 1,
      slidesToScroll: 1,
      arrows: true,
      dots: false,
      speed: 400,
      fade:true,
      autoplay: false,
      cssEase: 'linear',
      lazyLoad: 'ondemand',
      prevArrow: '<button type="button" class="slick-prev"></button>',
      nextArrow: '<button type="button" class="slick-next"></button>',
    });
  }



};
'use strict';

var formPreventivo = {
  
  options:{
    availabeDates: [],
    minDate: 0,
    mainClass: '.form-preventivo'
  },

  minCheckin : (typeof window.ABS_SearchFormMinCheckin == 'string') ? window.ABS_SearchFormMinCheckin : 0 ,
  maxCheckout : (typeof window.ABS_SearchFormMaxCheckout == 'string') ? window.ABS_SearchFormMaxCheckout : null ,

  /**
   * INIZIALIZZAZIONE SELCT2
   */
  select2Init:function(){
    $('select.s2', this.options.mainClass).select2({
      minimumResultsForSearch: Infinity
    });
    // Disabilita tastiera tablet e smartphone per select stilizzate
    $('.select2-focusser', this.options.mainClass).prop('readonly', true);
  },


  /**
   * Inizializzazione form search
   */
  init: function(){

    this.manageRestrictions();

    // var preloadImage = new Image();
    // preloadImage.src = location.protocol + '//' + location.host + '/modules/abs_search/images/strPicPreload.gif';

    var isMobileTT = {
      Android: function() {
        return navigator.userAgent.match(/Android/i);
      },
      BlackBerry: function() {
        return navigator.userAgent.match(/BlackBerry/i);
      },
      iOS: function() {
        return navigator.userAgent.match(/iPhone|iPad|iPod/i);
      },
      Opera: function() {
        return navigator.userAgent.match(/Opera Mini/i);
      },
      Windows: function() {
        return navigator.userAgent.match(/IEMobile/i);
      },
      any: function() {
        return (isMobileTT.Android() || isMobileTT.BlackBerry() || isMobileTT.iOS() || isMobileTT.Opera() || isMobileTT.Windows());
      }
    }

    // Select2 init
    this.select2Init();


    $.datepicker.setDefaults( $.datepicker.regional[ trad['iso2'] ] );

    // Num Guests
    this.numGuestsInit();
  },



  /**
   * SEARCH LAST DAY
   */

  searchLastDay: function(scope){
    var newScope = $( scope ).prev();
    var elemsToUse = $( '.ui-state-disabled', newScope );
    
    if ( elemsToUse.length > 0 ) {
      var elem = $( elemsToUse ).filter( ':last' );
      return elem.length > 0 ? elem : formPreventivo.searchLastDay(newScope);
    }
    else if ( $( '[data-handler="selectDay"]', newScope ).length > 0 ) {
      return formPreventivo.searchLastDay(newScope);
    }
    
    return [];
  },

  /**
   * Inizializzazione box ospiti
   */
  numGuestsInit: function(){



    $( '.guests', formPreventivo.options.mainClass ).on('click', function() {
      $( '.checkin, .checkout', formPreventivo.options.mainClass ).removeClass( 'active' );
      var that = $( this );

      
      if ( ! that.hasClass( 'active' ) ) {
        that.addClass( 'active' );
        
        $('.numGuests', formPreventivo.options.mainClass).removeClass('d-none');
      }
      else {
        that.removeClass( 'active' );
        
        $('.numGuests', formPreventivo.options.mainClass).addClass('d-none');
      }

    });



    //scelta ospiti
    $( '.childrenAge' , formPreventivo.options.mainClass).hide();
    $( '.numRooms button, .numAdults button, .numChildren button' , formPreventivo.options.mainClass).on( 'click', function() {
      var that = $( this );
      var parent = that.parent();
      var val = that.val();
      var thatId = that.attr( 'id' );
      var numRoom = thatId.split( '-' )[1];

      // console.log((parent.hasClass('numRooms')));
      if(parent.hasClass('numRooms')){        
        $('.numGuests', formPreventivo.options.mainClass).removeClass('singleRoom');
        $('.numGuests', formPreventivo.options.mainClass).removeClass('multiRooms');
        var boxGuestsAddRoomClass = (numRoom>1) ? 'multiRooms' : 'singleRoom' ;
        $('.numGuests', formPreventivo.options.mainClass).addClass(boxGuestsAddRoomClass);
      }

      that.addClass( 'selected' );
      that.siblings( 'button.selected' ).removeClass( 'selected' );

      //window.scrollBy(0,$('.numGuests').outerHeight());

      if ( parent.hasClass( 'numRooms' ) ) { //cambio camere
        $( 'input[name="bform[numRooms]"]' , formPreventivo.options.mainClass).val( val );
        $( '.roomSelect span small' , formPreventivo.options.mainClass).text( val );
        formPreventivo.showRoomsMobile();
      }
      else if ( parent.hasClass( 'numAdults' ) ) { //cambio adulti
        formPreventivo.computeGuestNumber();
        
        $( 'input[name="bform[reqRooms][' + numRoom + '][adults]"]' , formPreventivo.options.mainClass).val( val );
      }
      else if ( parent.hasClass( 'numChildren' ) ) { //cambio bambini
        formPreventivo.computeGuestNumber();
        
        // Assegniamo il valore selezionato al campo hidden
        $( 'input[name="bform[reqRooms][' + numRoom + '][child]"]', formPreventivo.options.mainClass ).val( val );
        
        var myResults = '';

        if ( val === 0 ) {
          that.siblings( '.childrenAge' , formPreventivo.options.mainClass).hide();
        }
        else {
          that.siblings( '.childrenAge' , formPreventivo.options.mainClass).show();
          var i = val;

          for ( var k = 1; k <= i; k++ ) {
            var myInputName = 'bform[reqRooms][' + numRoom + '][childAge]['+ k +']';
            var myInputId = 'room_children_age-' + numRoom + '-' + k;
            myResults = myResults + 
              '<span><a href="javascript:;" class="plus">+</a><em id="label_'+ myInputId+'">0</em>' +
              '<input type="hidden" class="childcounter" name="' + myInputName + '" id="' + myInputId + '"value="0" />' + 
              '<a href="javascript:;" class="min">-</a></span>';
          } //fine for

          var divChildren = that.siblings( '.childrenAge' ).children( 'div' ); 
          divChildren.html(myResults);
          
          // Select eta' bambini
          divChildren.find('span > a.plus').click(function() {

            var mySelf = $(this);
            var myVal = mySelf.siblings('.childcounter').val();

            if (myVal>=0 && myVal<18) {
              myVal = formPreventivo.safeParseInt(myVal) + 1;
              mySelf.siblings('.childcounter').val(myVal);
              mySelf.siblings('em').text(myVal);
            }
          });

          divChildren.find('span > a.min').click(function() {
            var mySelf = $(this);
            var myVal = mySelf.siblings('.childcounter').val();
            
            if (myVal>0 && myVal<=18) {     
              myVal = formPreventivo.safeParseInt(myVal) - 1;
              mySelf.siblings('.childcounter').val(myVal);
              mySelf.siblings('em').text(myVal);
            }
          });

        }
      }
    });
    // Mostro solo le camere opportune
    this.showRoomsMobile();

  },

  /**
   * SHOWROOMS MOBILE
   */

  showRoomsMobile: function(){    

    var maxNumRooms = formPreventivo.safeParseInt($('.numRooms > button.last', formPreventivo.options.mainClass).val());
    var actualNumRooms = formPreventivo.safeParseInt($('.numRooms > button.selected', formPreventivo.options.mainClass).val());

    // Nascondo/elimino da selezionato + 1 fino a massimo
    for (var i = actualNumRooms + 1; i <= maxNumRooms; i++) {
      
      var roomGuests = $('.roomGuests.' + i , formPreventivo.options.mainClass);
      roomGuests.hide();

      // Tolgo gli input hidden relativi
      if ($(formPreventivo.options.mainClass+' .bform_reqRooms_' + i + '_adults').length > 0) {
        $(formPreventivo.options.mainClass+' .bform_reqRooms_' + i + '_adults').remove();
      }

      if ($(formPreventivo.options.mainClass+' .bform_reqRooms_' + i + '_child').length > 0) {
        $(formPreventivo.options.mainClass+' .bform_reqRooms_' + i + '_child').remove();
      }
      
      // Disabilito gli input hidden dei childage (invece di rimuoverli li disabilito visto che l'aggiunta e la gestione sono diversi)
      for (var j = 1; j < 10; j++) {
        if ($(formPreventivo.options.mainClass+' .room_children_age-' + i + '-' + j , formPreventivo.options.mainClass).length > 0) {
          $(formPreventivo.options.mainClass+' .room_children_age-' + i + '-' + j , formPreventivo.options.mainClass).prop('disabled', true);
        }
      }
    }
    
    // Aggiungo/mostro da 1 a selezionato
    for (var ii = 1; ii <= actualNumRooms; ii++) {
      $('.roomGuests.' + ii , formPreventivo.options.mainClass).show();
      
      // Aggiungo gli input hidden con i valori di default
      if ($(formPreventivo.options.mainClass+' .bform_reqRooms_' + ii + '_adults').length === 0) {
        $('<input>').attr({
              type: 'hidden',
              class: 'bform_reqRooms_' + ii + '_adults',
              name: 'bform[reqRooms][' + ii + '][adults]',
              value: 2
          }).appendTo(formPreventivo.options.mainClass);
      }

      if ($(formPreventivo.options.mainClass+' .bform_reqRooms_' + ii + '_child').length === 0) {
        $('<input>').attr({
              type: 'hidden',
              class: 'bform_reqRooms_' + ii + '_child',
              name: 'bform[reqRooms][' + ii + '][child]',
              value: 0
          }).appendTo(formPreventivo.options.mainClass);
      }
      
      // Riabilito gli input hidden dei childage
      for (var jj = 1; jj < 10; jj++) {
        if ($(formPreventivo.options.mainClass+' .room_children_age-' + ii + '-' + jj, formPreventivo.options.mainClass).length > 0) {
          $(formPreventivo.options.mainClass+' .room_children_age-' + ii + '-' + jj, formPreventivo.options.mainClass).prop('disabled', false);
        }
      }
    }
    // Cambio del numero di ospiti
    formPreventivo.computeGuestNumber();

  },

  /**
   * CUMPUTE GUEST NUMBER
   */
  computeGuestNumber: function(){

    var adultsCount = 0;
    var childsCount = 0;
    
    for (var i = 1; i <= formPreventivo.safeParseInt($('.numRooms > button.selected', formPreventivo.options.mainClass).val()); i++) {
      adultsCount += formPreventivo.safeParseInt($('.roomGuests.' + i + ' > .numAdults > button.selected', formPreventivo.options.mainClass).text()) || 0;
      childsCount += formPreventivo.safeParseInt($('.roomGuests.' + i + ' > .numChildren > button.selected', formPreventivo.options.mainClass).text()) || 0;
    } //fine
    
    $( '.num-guests-adults', formPreventivo.options.mainClass ).empty().text( adultsCount );
    $( '.num-guests-childs', formPreventivo.options.mainClass ).empty().text( childsCount );

    
    //var guestCount = adultsCount + childsCount;

    //$( '#tot_num_guests_in_rooms' ).text( guestCount );

  }, 

  /**
   * SAFE PARSE INT
   */
  safeParseInt: function (valueToParse) {
    return parseInt( valueToParse, 10 );
  },

  manageRestrictions: function(){
    $('.restrictionClass', formPreventivo.options.mainClass).on('click', function(){

      var type = $(this).data('cal');
      if($(this).datepicker( "widget" ).is(":visible")) $(this).datepicker("hide");
      else formPreventivo.restrictionAjax(type);
    })
  },

  restrictionAjax: function(type, month, year){

    var serialize = $(formPreventivo.options.mainClass).serialize();
    var calendarType = type;
    var year = year || moment($('.input-'+calendarType, formPreventivo.options.mainClass).val(), 'DD-MM-YYYY').format('YYYY');
    var month = month || moment($('.input-'+calendarType, formPreventivo.options.mainClass).val(), 'DD-MM-YYYY').format('M');

    $.ajax({
      type: "POST",
      url: "/modules/abs_search/assets/getRestrictions.php?"+serialize,
      data: {action: 'getRestrictions', month: month, year: year, calendarType:calendarType},
      dataType: "JSON",
      cache: false,
      success: function(response){

        formPreventivo.options.availabeDates = response;
        if (formPreventivo.options.availabeDates['calendarType'] == 'checkin') {

          $('.input-checkin', formPreventivo.options.mainClass).datepicker( "refresh" );
          $('.input-checkin', formPreventivo.options.mainClass).datepicker({
            dateFormat: 'dd-mm-yy',
            minDate: 0,
            maxDate: '',
            nextText:'',
            prevText:'',
            numberOfMonths: 1,
            beforeShowDay: function(d){
              var found = false;
              var dateToFind = moment(d).format("YYYY-MM-DD");
              $.each(formPreventivo.options.availabeDates, function (k, v) {
                found = (k==dateToFind && (v.checkin===true || v.checkin===1));
                if(found===true) return false;
              });
              if(found){
                    return [dateToFind, 'mm-selectable', ''];
                  }else{
                    return [false, '', ''];
                  }
            },
            onChangeMonthYear: function(year, month, obj){
              formPreventivo.restrictionAjax('checkin', month, year);
            },
            onSelect: function(date, obj){
              var tmp_ = new Date(obj.selectedYear, obj.selectedMonth, obj.selectedDay);
              var dateToFind = moment(tmp_).format("YYYY-MM-DD");
              var stay = 1;
              $.each(formPreventivo.options.availabeDates, function (k, v) {
                if(k==dateToFind) stay = v.stay;
              });

              // //Se notti > 1 faccio vedere la label
              // if(stay>1){
              //   $('#minStayBar > span').html(stay);
              //   $('#minStayBar').fadeIn(200);
              // }else{
              //   $('#minStayBar > span').html("");
              //   $('#minStayBar').fadeOut(200);
              // }

              var month = moment(tmp_).add(stay, 'd').format('M');
              var year = moment(tmp_).add(stay, 'd').format('YYYY');
              formPreventivo.options.minDate = moment(tmp_).add(stay, 'd').format('DD-MM-YYYY');
              $('.input-checkout', formPreventivo.options.mainClass).val(formPreventivo.options.minDate);
              $('.input-checkout', formPreventivo.options.mainClass).datepicker('option', 'minDate', formPreventivo.options.minDate)
              formPreventivo.restrictionAjax('checkout', month, year);
            }
          });
          $('.input-checkin', formPreventivo.options.mainClass).datepicker("show");
          $('.input-checkin', formPreventivo.options.mainClass).datepicker( "refresh" );

        }else{

          $('.input-checkout', formPreventivo.options.mainClass).datepicker( "refresh" );
          $('.input-checkout', formPreventivo.options.mainClass).datepicker({
            dateFormat: 'dd-mm-yy',
            minDate: formPreventivo.options.minDate,
            maxDate: '',
            nextText:'',
            prevText:'',
            numberOfMonths: 1,
            beforeShowDay: function(d){
              var found = false;
              var dateToFind = moment(d).format("YYYY-MM-DD");
              $.each(formPreventivo.options.availabeDates, function (k, v) {
                var a = moment(k, 'DD-MM-YYYY');
                var b = moment(formPreventivo.options.minDate, 'YYYY-MM-DD');
                var diff = a.isSameOrAfter(b);
                found = (k==dateToFind && (v.checkin===true || v.checkin===1));
                if(found===true) return false;
              });
              if(found){
                    return [dateToFind, 'mm-selectable', ''];
                  }else{
                    return [false, '', ''];
                  }
            },
            onChangeMonthYear: function(year, month, obj){
              formPreventivo.restrictionAjax('checkout', month, year);
            }
          });
          $('.input-checkout', formPreventivo.options.mainClass).datepicker("show");
          $('.input-checkout', formPreventivo.options.mainClass).datepicker( "refresh" );

        }   
      }
    });

  }


};


'use strict'
var ABS_BoxStructuresMap = {

  options:{
    defaultLat:45.636718,
    defaultLon:13.060691
  },

  /**
   * ARRAY POPOLATO DA FUNZIONE DI FILTRO RICERCA PER FILTRARE I RISULTATI ANCHE NELLA MAPPA
   * @type {Array}
   */
  filterStructures:[],
  showStructures:[],

  checkGoogleLib : function(){
    
    if(typeof google === 'undefined') return false;
    if(typeof google.maps === 'undefined') return false;
    return true;
  },

  /**
   * INSTALL MAPPA 
   * Vengono instanziati i dati necessari e caricato condizionalmente la libreria googlemaps se non presente
   * @param  {string} divId      : id mappa
   * @param  {JSON} structures : struttura dati punti mappa
   */
  install: function(divId,structures){

    // Salvo nella variabile i dati di inizializzazione
    // per poterli riutilizzare al reload della mappa
    this.divId = divId;
    this.structures = structures;

    console.log(this.checkGoogleLib());

    // Se non c'è la libreria di google maps la carico
    if(!this.checkGoogleLib()){
      $.ajax({
        url: 'https://maps.google.com/maps/api/js?v=3&language='+ trad['iso2'] +'&callback=ABS_BoxStructuresMap.init',
        dataType: 'script',
        async:true
      });

    } else {
      ABS_BoxStructuresMap.init();
    }

  },
  
  /**
   * INIT MAPPA STRUTTURE CMS
   */
  
  init:function() {

    // var divId = this.divId;
    // var structures = this.structures;

    var structures = this.structures;

    // Varibile storage ultima infowindow aperta
    var prev_infowindow =false;

   
    if(Object.keys(structures).length===0) return;   


    // Stili mappa per nascondere punti interesse (altre strutture turistiche)
    var mapStyles =[
      {
          featureType: 'poi',
          elementType: 'labels',
          stylers: [
                { visibility: 'off' }
          ]
      }
    ];

    var mapOptions = {
      mapTypeId: google.maps.MapTypeId.ROADMAP,
      zoomControl: true,
      zoomControlOptions: {
          style: google.maps.ZoomControlStyle.SMALL
      },
      styles: mapStyles 
    }

    var markerBounds = new google.maps.LatLngBounds();

    var map = new google.maps.Map(document.getElementById(this.divId), mapOptions);
    var markers = [];
    var infowindows = [];
    var tmpMarkers = [];

    for (var key in structures) {
      var str = structures[key];

      // Filtro le strutture secondo l'array dell'oggetto che viene popolato dal filtro della pagina
      if(this.filterStructures.indexOf(Number(key)) !== -1) continue;

      if(parseFloat(str['lat']) == 0 || parseFloat(str['lon']) == 0) continue;


      
      if (this.showStructures.length>0){
        if (this.showStructures.indexOf(Number(key)) === -1) continue;
      }

      var myLatLng = new google.maps.LatLng(parseFloat(str['lat']), parseFloat(str['lon']));
      
      // Estendo i limiti per il centramento della mappa
      markerBounds.extend(myLatLng);
      

      // Imposto il marker
      markers[key] = new google.maps.Marker({
        position: myLatLng,
        map: map,
        icon: ((typeof str.let === 'string') ? '/modules/abs_search/images/asideMap/ico_' + str.let+'.png'
         : '/images/markerStructure.png'),
        elKey : key
      });
      tmpMarkers.push(markers[key]);

      // Imposto il balloon
      var balloon = ''+
      '<div class="abs-structures-map__balloon" onclick="ABS_BoxStructuresMap.gotoUrl(\'' + str.url + '\');">' +
        '<div class="row">'+
          '<div class="col-auto imgHolder">'+
            '<img class="img-fluid" src="'+ str.img +'" />'+
          '</div>'+
          '<div class="col">'+
            '<h4>'+str.name+'</h4>'+
            '<div><span class="struct_star struct_star_'+str.rating+'"></span></div>'+
            '<small>'+str.addr+'</small>'+
          '</div>'+
          '<div class="desc">'+ str.desc +'</div>'+
          '<div class="overlay anim">'+
            '<button type="button" class="btn theme-btn theme-btn--blue-sky text-uppercase anim">'+str.btn+'</button>'+
          '</div>'+
        '</div>'+
      '</div>';

      infowindows[key] = new google.maps.InfoWindow({
        content: balloon
      });
      
      // Click marker con apertura infowindow
      google.maps.event.addListener(markers[key], 'click', function() {

        // Chiudo infowindow precedente
        if( prev_infowindow ) {
           prev_infowindow.close();
        }
        prev_infowindow = infowindows[this.elKey];

        infowindows[this.elKey].open(map, this);
        map.panTo(infowindows[this.elKey].getPosition());
        // siteGlobal.goToPos('#'+ABS_BoxStructuresMap.divId);    
      });
    }

    // Se non c'è nessun punto centro la mappa su bibione
    if(markers.length===0){

      var myLatLng = new google.maps.LatLng(this.options.defaultLat, this.options.defaultLon);
      markerBounds.extend(myLatLng);

      markers[0] = new google.maps.Marker({
        position: myLatLng,
        map: map,
        icon: '/images/noMarker.png'
      });
    }

    // Fix per zoom su punti vicini
    if (markerBounds.getNorthEast().equals(markerBounds.getSouthWest())) {
       var extendPoint1 = new google.maps.LatLng(markerBounds.getNorthEast().lat() + 0.001, markerBounds.getNorthEast().lng() + 0.001);
       var extendPoint2 = new google.maps.LatLng(markerBounds.getNorthEast().lat() - 0.001, markerBounds.getNorthEast().lng() - 0.001);
       markerBounds.extend(extendPoint1);
       markerBounds.extend(extendPoint2);
    }

    var clusterStyles = {
      styles:[{
              url: '/images/markerStructureCluster.png',
              height: 33,
              width: 24,
              anchor: [3, 0],
              textColor: '#eb7207',
              textSize: 14
            }]
    };
    var markerCluster = new MarkerClusterer(map, tmpMarkers,clusterStyles);
    // Centro la mappa la prima volta
    map.fitBounds(markerBounds);



    // Al resize ricentro la mappa secondo i punti contenuti
    google.maps.event.addDomListener(window, 'resize', function() {
      map.fitBounds(markerBounds);
    });


  },

  /**
   * UPDATE MAPPA 
   * L'array dei filtri viene popolato dalla funzione di filtro della lista prodotti
   * I dati base della mappa sono "salvati" all'interno dell'oggetto al momento dell'inzializzazione
   */
  update:function(){
    this.init();
  },


  /**
   * GOTO URL BALLOON
   * @param  {str} url : url destinazione
   */
  gotoUrl:function(url){
    window.location.href=url;
  }

};
// 'use strict';
var fgm = {

  mainClass: '.frequent-guest-menu',
  serviceHubStag: 'http://servicehub-stg.abs-one.com',
  serviceHubProd: 'https://servicehub.abs-one.com',
  serviceABSProd: 'https://azzurroclub.abs-one.com',
  installation: 'AZZ',
  // installation : 'RES',
  portal: '01',
  structure: '0',
  structure_id: 0,
  basePath: '',
  baseLinkPath: '',

  init: function () {
    // this.setPath();
    // this.objLoginLib();
    // this.formToggleBtn();
    // this.overlayToggleBtn();
    
    // this.activateLogin();
    // this.activateLinks();
    // this.activateNotLoggedTooltip();
    this.emailAlertBtn();
  },

  objLoginLib: function(){
    $.getScript('https://servicehub.abs-one.com/AbsFrequentGuest/js/login.min.js', function () {
      fgm.objLogin();
      fgm.showMenu();
    });
  },
  objLogin: function () {

    this.login = new SH_AFG_Login(
      fgm.installation, // codice installazione
      fgm.portal, // codice portale
      fgm.structure_id, // codice struttura
      $,                // jquery
      'prod' // default = 'prod'
    );
    this.login.enableRemoteSyncCalls(true);
  },



  emailAlertBtn: function () {

    var $btn = $('.fgm-email-alert__btn');
    if ($btn.length == 0) {
      return false;
    }

    var $stid = $btn.data('stid');
    if ($stid !== undefined) {
      this.structure_id = $stid;
    }

    // var userData = this.getUserData();
    var userEmail = '';

    var requestParams = {
      language: 'it',
      portal_id: fgm.portal,
      portal_code: fgm.installation,
      structure_id: fgm.structure_id,
      cookies: document.cookie
    }

    if (
      typeof userData !== 'undefined' &&
      userData !== null &&
      typeof userData.profile !== 'undefined' &&
      typeof userData.profile.email !== 'undefined'
      ) {
        requestParams.email = userData.profile.email;
        userEmail = userData.profile.email;
      }

    $btn.click(function () {
      var res = fgm._sendRequestABS(
        'POST',
        '/services/frequentGuestRegisterToService.php',
        requestParams
      );
      fgm.manageEmailAlertResponse(res, userEmail);
    });
  },

  /**
   * Submit servizio email alert ABS senza feedback frontend
   * Utilizzata nei form contatti come checkbox
   * @param  {string} userEmail   : Email
   * @param  {int} structureId    : Id Struttura
   */
  silentEmailAlertSubscribe: function (userEmail, structureId) {

    var requestParams = {
      language: 'it',
      portal_id: fgm.portal,
      portal_code: fgm.installation,
      structure_id: structureId,
      cookies: document.cookie,
      email: userEmail
    }

    var res = fgm._sendRequestABS(
      'POST',
      '/services/frequentGuestRegisterToService.php',
      requestParams
    );
  },

  manageEmailAlertResponse: function (res, inputValue) {

    var inputValue = inputValue || '';

    if (res.success == true) {
      // Registrazione avvenuta
      swal(
        trad['fgma__subscribed-title'],
        trad['fgma__subscribed-text'].replace('###EMAIL###', inputValue),
        "success"
      );

    } else {

      // Registrazione non avenuta
      switch (res.errorCode) {

        case 'registered_already':
        case 'registered_alredy':
          // Già registrato
          swal(
            trad['fgma__email-already-registered-title'],
            trad['fgma__email-already-registered-text'].replace('###EMAIL###', inputValue),
            "warning"
          );
          break;

        case 'missing_email':
          // Richiedere email
          fgm.showEmailAlertForm();
          break;

        default:
          // Errore generico
          swal(
            trad['Error'],
            trad['error_email_send'],
            "danger"
          );
          break;
      }
    }


  },

  showEmailAlertForm: function () {

    var FiltroMail = /^([a-zA-Z0-9_\.\-])+\@(([a-zA-Z0-9\-]{2,})+\.)+([a-zA-Z0-9]{2,})+$/;

    swal({
      title: trad['fgma__email-registration-title'],
      text: trad['fgma__email-registration-text'],
      type: "input",
      showCancelButton: true,
      inputPlaceholder: "Email",
      closeOnConfirm: false,
      },function (inputValue) {
        if (inputValue === false) return false;
        if (inputValue === "" || !FiltroMail.test(inputValue)) {
          swal({
            title: '',
            text: trad['fgma__email-registration-warning'],
            type: 'warning'
          });
          return false;
        } else {
          // Invio la mail alla registrazione servizio ABS
          setTimeout(function () {
            fgm.showEmailAlertGDPR(inputValue);
          }, 250);
          return false;
        }
      });
  },

  showEmailAlertGDPR: function (inputValue) {

    swal({
      title: trad['fgma__privacy-title'],
      text: trad['fgma__privacy-text'],
      type: "info",
      showCancelButton: true,
      confirmButtonClass: "btn-success",
      confirmButtonText: trad['fgma__privacy-confirm'],
      cancelButtonClass: "btn-danger",
      cancelButtonText: trad['fgma__privacy-deny']
    }, function (result) {
      if (result == true) {
        var submitRes = fgm.submitEmailAlertForm(inputValue);
        fgm.manageEmailAlertResponse(submitRes, inputValue);
      } else {
        // swal("Cancelled", "Your imaginary file is safe :)", "error");
      }
    }
    );
  },

  submitEmailAlertForm: function (email) {

    return fgm._sendRequestABS(
      'POST',
      '/services/frequentGuestRegisterToService.php', {
        email: email,
        language: 'it',
        portal_id: fgm.portal,
        portal_code: fgm.installation,
        structure_id: fgm.structure_id,
        cookies: document.cookie
      }
    );

  },

  setPath: function () {
    this.basePath = this.serviceHubProd + '/absfrequentguest/' + this.installation + '/' + this.portal + '/' + this.structure;
    this.baseLinkPath = this.serviceHubProd + '/AbsFrequentGuest/' + this.installation + '/' + this.portal + '/' + this.structure;
  },

  showMenu: function () {
    var is_logged = this.login.isLogged();
    var $menuLogged = $(this.mainClass + '--not-logged');
    var $menuNotLogged = $(this.mainClass + '--logged');
    var $menuNotLoggedPopover = $(this.mainClass + '__popover-wrap');
    var $menuOverlay = $(this.mainClass + '__overlay-wrap');
    var $wrap = $(this.mainClass);

    if (is_logged) this.populateUserMenu();

    $wrap.toggleClass('loading', false);
    $menuLogged.toggleClass('d-none', is_logged);
    $menuNotLogged.toggleClass('d-none', !is_logged);

    // Al click sul pulsante di apertura overlay,
    // chiudo il popover informativo
    $menuOverlay.on('show.bs.collapse', function () {
      $menuNotLoggedPopover.popover('d-none');
    });
  },

  activateNotLoggedTooltip: function () {
    $el = $(this.mainClass + '__popover-wrap');
    $el.popover({
      placement: 'left',
      container: 'body',
      trigger: 'hover',
      html: 'true',
      template: '<div class="popover frequent-guest-menu__popover" role="tooltip"><div class="arrow"></div><h3 class="popover-title"></h3><div class="popover-content"></div></div>',
      title: trad['fg_popover_title'],
      content: trad['fg_popover_content']
    });
  },

  activateLinks: function () {
    $('#FG-LOSTPW').prop('href', this.baseLinkPath + '/login/password/reset?lang=' + trad['iso2']).attr('target', '_blank');
    $('#FG-REGISTER').prop('href', this.baseLinkPath + '/register?lang=' + trad['iso2']).attr('target', '_blank');
  },


  getUserData: function () {

    var res = this.login.getUserData();

    return res;
  },

  populateUserMenu: function () {

    var userData = this.getUserData();
    if (userData === null) {
      console.log('Error retrieving frequent guest data!');
      return false;
    }

    // Dati utente
    var $btn = $('#FG-HELLO');
    var hello = userData.label['hello user'].replace('%s', userData.profile.fullname);
    var text = $btn.text();
    $btn.text(text.replace('###FG-HELLO###', hello));

    // Menu utente
    var menuItems = '';
    var menuObj = userData.menu;
    for (var i in menuObj) {
      if (menuObj[i].showInMenu) {
        if (!menuObj.hasOwnProperty(i)) continue;
        menuItems += '<li><a target="_blank" href="' + fgm.baseLinkPath + '/client#/' + menuObj[i].action + '?lang=' + trad['iso2'] + '">' + userData.label[menuObj[i].label] + '<span>&rsaquo;</span></a></li>';
      }
    }
    var $menu = $('#FG-MENU');
    $menu.html(menuItems);

  },

  doLogin: function (username, password) {
    var res = this.login.login({
      username: username,
      password: password
    });

    // console.log(res);
    if (res === null) return false;
    return res.is_logged;
  },

  doLogout: function (updateMenu) {

    updateMenu = updateMenu || false;

    this.login.logout();

    if (updateMenu) fgm.showMenu();


  },

  activateLogin: function () {

    var $form = $(this.mainClass + '__form');

    $form.submit(function () {
      $form.toggleClass('loading', true);
      var username = $(this).find('input[name="email"]').val();
      var password = $(this).find('input[name="password"]').val();

      var loginRes = fgm.doLogin(username, password);

      // console.log(loginRes);

      $form.toggleClass('loading', false);

      if (loginRes) {
        // Login ok
        swal({
          type: 'success',
          allowOutsideClick: true,
          title: trad['fg_login_success_title'],
          text: trad['fg_login_success']
        });

        // Mostro menù per utente loggato
        fgm.showMenu();

      } else {
        // Login ko
        swal({
          type: 'warning',
          allowOutsideClick: true,
          title: trad['Attention'],
          text: trad['fg_login_error']
        });
      }



      return false;
    });
  },

  formToggleBtn: function () {

    var $form = $(this.mainClass + '__form');

    $(this.mainClass + '__btn-form-toggle').click(function () {
      $form.addClass('active');
    });

    $(this.mainClass + '__btn-form-close').click(function () {
      $form.removeClass('active');
    });
  },

  overlayToggleBtn: function () {

    var $btn = $(this.mainClass + '__btn-overlay-close');
    $btn.click(function () {
      $(fgm.mainClass + '__btn--main').trigger('click');
    });

  },

  _sendRequest: function (httpMethod, resourceUrl, payload) {
    $.support.cors = true;
    ret = null;
    $.ajax({
      method: httpMethod,
      url: fgm.basePath + resourceUrl,
      data: JSON.stringify(payload),
      //data: payload,
      contentType: "application/json",
      cache: false,
      async: false,
      dataType: 'json',
      crossDomain: true,
      xhrFields: {
        withCredentials: true
      },
      success: function (res, textStatus, request) {
        ret = res;
      },
      error: function (request, textStatus, errorThrown) {
        // ret null;
      }
    })

    return ret;
  },

  _sendRequestABS: function (httpMethod, resourceUrl, payload) {
    $.support.cors = true;
    var ret = {
      success: false,
      errorMessage: 'Error on call',
      errorCode: 'error_executing_call'
    };
    $.ajax({
      method: httpMethod,
      url: fgm.serviceABSProd + resourceUrl,
      data: payload,
      //data: payload,
      // contentType: "application/json",
      cache: false,
      async: false,
      // dataType: 'json',
      crossDomain: true,
      xhrFields: {
        withCredentials: true
      },
      success: function (res, textStatus, request) {
        ret = res;
      },
      error: function (request, textStatus, errorThrown) {
        // ret null;
      }
    })

    return JSON.parse(ret);
  }


};
'use strict';
var siteGlobal = {

  options : {
    breakpoints: { 480: 'xxs',768: 'xs', 980: 'sm', 1200: 'md', 5000: 'lg' },
  },

  defaultScrollOffset:0,

  initReady: function () {
    barTop.checkVisual();
    this.breakPointUtility();
    this.goToPosOffsetCalc();
    this.linkAll();
    this.linkParts();
    new WOW({
      mobile: false
    }).init();
    this.updateBodyTemplate();
    this.uiBlockButtons();
  },

  initLoad: function () {    
    this.goToPosOffsetCalc();
    this.breakPointUtility();
    visual.init();
    setTimeout(function(){
      lazyLoad.init();
      visual.load();
      listHotels.init();
      galleryBody.init();
    },150);
    this.gmapPageInit();
    this.readMoreInit('.read-more-text', 2);
    MM_ABS_offerlist.initReady();

    this.templateScriptsInit();

    this.autoScroll();

    fgm.init();
   

    if (typeof ABS_MapPoints === 'object' && $('#ABS-map-structures').length == 1) {
      ABS_BoxStructuresMap.install('ABS-map-structures', ABS_MapPoints);
    }
    $('[data-toggle="tooltip"]').tooltip();

  },

  initFirstLoad: function(){
    barbaJs.init();
    menuOverlay.init();    
    this.liveChatIframe();
    if (navigator.userAgent.match(/iPhone|iPad|iPod/i) !== null) {
      $('#live-chat-modal-trigger')
      .unbind('click')
      .attr('data-toggle', '')
      .attr('data-target', '')
      .attr('href', 'https://acvmcs.m-lab.it/apps/multichannel?widgetId=default&channelType=CHAT')
      .attr('target', '_blank');
    }
  },

  rebuildAllEvents: function(){
    this.initReady();
    this.initLoad();
  },

  initResize: function(){
    this.goToPosOffsetCalc();
    this.breakPointUtility();
    absSearch.resize();
  },

  initResizeEnd: function(){
    this.goToPosOffsetCalc();
  },

  initScroll: function(){
  },

  autoScroll: function () {
    var scrollTarget = '.autoscroll-' + siteGlobal.breakPoint;
    if ($(scrollTarget).length > 0) {
      siteGlobal.goToPos($(scrollTarget));
    }
  },

  /**
   * OPEN MODAL (Usata di solito per social)
   * @param  {string} url : url pagina da aprire nella nuova finestra
   */
  openModal:function(url) {
    url = decodeURIComponent(((url) + '').replace(/\+/g, '%20'));
    window.open(url, 'socials', 'height=450,width=880,toolbar=no,directories=no,status=no,menubar=no,scrollbars=no,resizable=no,modal=yes');
  },

  uiBlockButtons: function(){
    var $btns = $('.do-ui-block');
    $btns.on('click',function(e){
      e.preventDefault();
      startBlockUI(trad['msg1_search'], trad['msg2_search']);
      window.location.href = $(this).attr('href');
      return false;
    });
  },


  /**
   * ELEMENTI INTERAMENTE LINKABILI
   */
  linkAll: function(){
    $('.linkFrom').each(function() { 
        var elemToLink = $(this).parents('.linkTo');
        var pageLink = $(this).attr('href');
        var linkTarget = (typeof $(this).attr('target')!=='undefined') ?  $(this).attr('target') : '' ;
        var nobarba = $(this).hasClass('no-barba');
      if(pageLink !== undefined && pageLink!=='javascript:;') {
        elemToLink.addClass('pointer').click({myLink:pageLink, myTarget:linkTarget, nobarba:nobarba},function(e){
          e.preventDefault();
          if(e.data.myTarget==='_blank'){
            window.open(e.data.myLink);
          } else {
            if(typeof barbaJs !=='undefined' && !e.data.nobarba){
              Barba.Pjax.goTo(e.data.myLink); 
            } else {
              location.href = e.data.myLink;
            }
          }
        });
      }
    }); 
  },

  /**
   * ELEMENTI PARZIALMENTE LINKABILI (aree diversificate all'interno dello stesso contenitore)
   */
  linkParts: function(){
    var elems = $('.linkFromPart');
    var elemsLg = elems.length;
    if(elemsLg>0){
      var i=0;
      for (i; i<=elemsLg-1; i++) {
        var el = $(elems[i]);
        var pageLink = el.attr('href');
        if(pageLink !== undefined) {
          var blockWrap = el.parents('.linkWrap');
          var elemsToLink = $('.linkToPart',blockWrap);
          var nobarba = $(this).hasClass('no-barba');
          elemsToLink.addClass('pointer').click({pageLink:pageLink,nobarba:nobarba},function(e){
            if(typeof barbaJs !=='undefined' && !e.data.nobarba){
              Barba.Pjax.goTo(e.data.pageLink); 
            } else {
              location.href = e.data.pageLink;
            }
          });
        }
      }
    }
  },

  /**
   * READ MORE TEXT
   * @param  {string} selector : selettore contenitore testo
   * @param  {int} rows        : numero di righe
   */
  readMoreInit: function (selector, rows) {

    var $el = $(selector);
    if ($el.length === 0) return;

    $el.each(function(){

      $this = $(this);
      
      if (typeof $this.data('readmoreRows') != 'undefined') rows = $this.data('readmoreRows');
 
  
      var collapsedHeight = parseInt($this.css('line-height')) * rows;
  
      $this.readmore({
        speed: 150,
        collapsedHeight: collapsedHeight,
        heightMargin: 10,
        moreLink: '<a href="javascript:;" class="btn btn-readmore"><span class="glyphicon glyphicon-plus"></span>' + trad['read_more_text'] + '</a>',
        lessLink: '<a href="javascript:;" class="btn btn-readmore"><span class="glyphicon glyphicon-minus"></span>' + trad['read_less_text'] + '</a>'
      });

    });


  },

  /**
   * COMPORTAMENTI JS A SECONDA DELLA DIMENSIONE PAGINA
   */
  breakPointUtility: function(){

    siteGlobal.breakPoint = 'xs';

    for (var el in this.options.breakpoints){
      if (this.options.breakpoints.hasOwnProperty(el)) {
        if ( $(window).width() <= (el-1) ){
            siteGlobal.breakPoint = this.options.breakpoints[el];
            break;
        }
      }
    }      
  },

  /**
   * OVERLAY LOADER
   */
  overlayAllOff : function() {
      setTimeout(
          function(){
              $('.overlay-all').remove();;
          },
      150);   
  },

  /**
   * CALCOLA OFFSET PER ANIMAZIONE SCROLL PAGINA
   */
  goToPosOffsetCalc:function(status){
    siteGlobal.defaultScrollOffset = 0;
    
    // Elementi da considerare per l'offset (un selettore per elemento)
    // Es. var offsetElements=['#barTopWrap'];
    var offsetElements = ['.bar-top__wrap', '#boxFormSearch'];
    
    if (offsetElements.length > 0) {
      for (var el in offsetElements){
        var $el = $(offsetElements[el]);
        if($el.length>0){

          // L'elemento viene considerato nel calcolo solo se è in position fixed
          // Escludo gli elementi in fixed ma bottom
          // Questo per le barre che a seconda del template stanno sopra o sotto (es. booking)
          var elPos = $el.css('position');
          var elBottom = $el.css('bottom');
          
          if (elPos === 'fixed' && elBottom != '0px' && $el.is(':visible')) siteGlobal.defaultScrollOffset += $el.outerHeight(true);
        }
      }
    }

    // Gestisco il padding del body rispetto all'offset
    $('body').css({'paddingTop':siteGlobal.defaultScrollOffset});


  }, 

  /**
   * SCROLL PAGINA
   */
  goToPos: function(ref,offset){
    // Offset Default con calcolo barre menu top
    var defaultOffset = siteGlobal.defaultScrollOffset;

    if(typeof offset === 'undefined') offset = defaultOffset;
    $ref = (typeof ref==='string') ? $(ref) : ref ;
    if($(ref).length==0)
      return false;
    var myPos = $ref.offset().top-offset;
    $('html,body').animate({
      scrollTop: myPos}, 
      { duration: 600 }
    );
  },

  gmapPageInit:function(){
    if($('#gmap_jquery').length>0 && typeof mapConfig!=='undefined') {
     mapConfig.params.drag = ($(document).width() > 768) ? true : false;
     setDirection.set(
      'gmap_jquery', 
      mapConfig.config,
      mapConfig.params.zoom,
      mapConfig.params.type,
      mapConfig.params.showCursor,
      mapConfig.params.drag,
      mapConfig.params.scroll
     );
    }
  },

  updateBodyTemplate:function(){
    var template = $('.barba-container').data('template');

    if (template !== ''){
      $('body').attr('data-template',template);
    }
  },

  /**
   * Inizializzazione scripts per template;
   */
  templateScriptsInit:function(){

    var template = $('.barba-container').data('template');

    switch (template) {
      
      case 't_page_offer':
        
        offerPage.install();
        offerCalendar.init();
        setInfoHide('#minisite-funct-btn-wrapper');
        break;

      case 't_minisite_custom':
        absMinisite.init();
        absMinisite.initLoad();
        ABS_searchFormMinisite.init();
        ABS_searchForm.init();
        break;

       case 't_search_custom':
         absSearch.init();
         break;

      case 't_search_offers':
        absSearchOffers.init();
        break;

      case 't_page_preventivo':
        formPreventivo.init();
        break;

      case 't_page_questionaire':
        BookingOne.addIframe('bookingframe');
        break;
    }
  },

  liveChatIframe: function () {
    var $modal = $('#live-chat-modal');
    var $iframe = $modal.find('iframe');
    $modal.on('shown.bs.modal', function () {
      $iframe.attr('src', $iframe.attr('attr_src'));
    });
  }

};





/**
 * INIZIALIZZAZIONE SITO
 */
(function () {

  siteGlobal.initReady();

  $( window ).on("load",function() {
      siteGlobal.initLoad();
      siteGlobal.initFirstLoad();
  });

  $(window).resize(function(){
    siteGlobal.initResize();
  });
  

  if(typeof $(window).resizeend === 'function'){
    $(window).resizeend(function () {
      siteGlobal.initResizeEnd();
    });
  } else {
    $(window).resize(function(){
      siteGlobal.initResizeEnd();
    });
  }

  $(window).scroll(function(){
    siteGlobal.initScroll();
  });

}());